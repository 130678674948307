
class AppStore {
    constructor() {
        this.is_connected = true;
        this.app_is_loading = false;
        this.started_app = false;
        this.is_first_launch = true;
        this.is_old_version = false;
        this.locale = "fr";
        this.db = null;
        this.query_params = '';

        this.rating = {
            rate: 0,
            comment: '',
            device_id: '',
            app_id: '',
            source: '',
            locale: '',
            posted_at: null,
            timezone: null,
            questions_answers: {},
        };
        this.defaultRating = {
            rate: 0,
            comment: '',
            device_id: '',
            app_id: '',
            source: '',
            locale: '',
            posted_at: null,
            timezone: null,
            questions_answers: {},
        };
        //Used for model 1 et 2
        this.conditionedQuestion = [];
        this.conditionalQuestions = [];
        this.hiddenQuestions = [];
        this.bookmarkedQuestions = [];
        this.appreciation_questions = [];
        this.profil_questions = [];
        this.context_questions = [];
        this.questions_by_page = [];

        // //Used for model 3
        // this.questions = [];

        this.data = {};
        this.toggleCGU = false;

        this.mobileAlreadyPostRating = false;
    }
}

export default new AppStore();
