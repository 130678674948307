import _ from 'lodash';
import AppStore from "../stores/AppStore";
import moment from 'moment';
import i18n from '../config/i18n';
// import ModelLogic from "../logics/ModelLogic";
// import AuthLogic from "../logics/AuthLogic";
// import {EventBus} from "./Request";
// import AppStore from "../stores/AppStore";
// import PouchDB from "pouchdb";

export default class Helpers {

    // /**
    //  * @param currentPagination
    //  * @param newPagination
    //  */
    // static syncPagination(currentPagination, newPagination) {
    //     currentPagination.page = newPagination.current_page;
    //     currentPagination.itemsPerPage = newPagination.per_page;
    // }
    //
    // /**
    //  * @param pagination
    //  * @returns {{page, limit: *|number}}
    //  */
    // static parsePagination(pagination) {
    //
    //     let params = {
    //        /* page: false,
    //         limit: -1*/
    //     };
    //
    //     if (pagination) {
    //         if (pagination.page && parseInt(pagination.page) > 0) {
    //             params.page = pagination.page;
    //         }
    //
    //         if (pagination.itemsPerPage && parseInt(pagination.itemsPerPage) > 0) {
    //             params.limit = pagination.itemsPerPage;
    //         }
    //
    //         _.merge(params, {
    //             //page: pagination.page && parseInt(pagination.page) > 0 ? parseInt(pagination.page) : params.page,
    //             //limit: pagination.itemsPerPage && parseInt(pagination.itemsPerPage) > 0,
    //             sortBy: pagination.sortBy && pagination.sortBy.length ? pagination.sortBy[0] : "created_at",
    //             descending: pagination.sortDesc && pagination.sortDesc.length ? +pagination.sortDesc[0] : "0"
    //         })
    //
    //         return params;
    //     }
    //
    //     return {
    //         sortBy: "created_at",
    //         descending: "0"
    //     };
    //
    //
    //     /*return {
    //         page: pagination.page,
    //         limit: pagination.itemsPerPage,
    //         sortBy: pagination.sortBy.length ? pagination.sortBy[0] : "created_at",
    //         descending: pagination.sortDesc.length ? +pagination.sortDesc[0] : "0"
    //     }*/
    // }
    //
    // /**
    //  *
    //  * @param search
    //  * @returns {boolean}
    //  */
    // static parseFilters(filters) {
    //     return _.pickBy(filters, (filter) => {
    //         return filter !== '';
    //     });
    // }
    //
    // /**
    //  * @returns {{descending: boolean, page: number, rowsPerPage: number, sortBy: null, totalItems: number}}
    //  */
    // static initPagination(rowsPerPage = 20, page = 1, sortBy = null, descending = null) {
    //     return {
    //         rowsPerPage,
    //         page,
    //         sortBy,
    //         descending,
    //     }
    //
    // }
    //
    // static ucFirst(string) {
    //     return string.charAt(0).toUpperCase() + string.slice(1);
    // }
    //
    static parseDate(date, format = 'DD/MM/YYYY') {
        if(i18n.locale === 'en') {
            if(format === 'DD/MM/YYYY') {
                format = 'MM/DD/YYYY'
            }
            if(format === 'DD/MM/YYYY HH:mm:ss') {
                format = 'MM/DD/YYYY HH:mm:ss'
            }
        }

        return moment(date).format(format)
    }
    //
    // static parseActualDate(format = 'DD/MM/YYYY') {
    //     if(i18n.locale === 'en') {
    //         if(format === 'DD/MM/YYYY'){
    //             format = 'MM/DD/YYYY'
    //         }
    //         if(format === 'DD_MM_YYYY'){
    //             format = 'MM_DD_YYYY'
    //         }
    //     }
    //     return moment().format(format)
    // }
    //
    // static isFormDirty(component) {
    //     const isFormDirty = Object.keys(component.fields).some(key => component.fields[key].dirty);
    //
    //     component.$emit('update:isFormDirty', isFormDirty)
    //
    //     return isFormDirty;
    // }
    //
    // static getLocalizedDate(date) {
    //     return Helpers.ucFirst(date.format("dddd D MMMM YYYY, HH:mm"))
    // }
    //
    // static truncate(string, length, dots = true){
    //     return string && (string.length > length) ? string.substr(0, length-1) + (dots ? '&hellip;' : '') : string;
    // }
    //
    // static millisecondsToMinutesSeconds = (ms) => {
    //     if (!ms || ms === 0) return '00:00:00';
    //
    //     let duration = moment.duration(ms, 'milliseconds');
    //     let fromMinutes = Math.floor(duration.asMinutes());
    //     let fromSeconds = Math.floor(duration.asSeconds() - fromMinutes * 60);
    //
    //     return Math.floor(duration.asSeconds()) >= 60 ? (fromMinutes <= 9 ? '0' + fromMinutes : fromMinutes) + ':' + (fromSeconds <= 9 ? '0' + fromSeconds : fromSeconds)
    //         : '00:' + (fromSeconds <= 9 ? '0' + fromSeconds : fromSeconds);
    // }
    //
    static uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    //
    // static updateItemsPerPage (items_per_page) {
    //     const user = AuthLogic.user()
    //     if(user.items_per_page !== items_per_page){
    //         ModelLogic
    //             .updateOne('users', user.id + '/items_per_page', {'items_per_page': items_per_page})
    //             .then((result) => {
    //                 AuthLogic.removeUser()
    //                 AuthLogic.loadUser()
    //             })
    //             .catch((error) => {
    //                 console.error(error)
    //             })
    //     }
    // }
    //
    // static isRouteWithGlobalFilters(routeName){
    //     return _.includes(['dashboards', 'ratings', 'dashboard-unit-group', 'dashboard'], routeName)
    // }
    //
    // static convertMinutesInMilliseconds(time){
    //     return time*60000
    // }
    //
    // static convertSecondsInMilliseconds(time){
    //     return time*1000
    // }
    //
    // static convertRateToVRating(avgRate){
    //     if ((avgRate % 1) < 0.25) {
    //         avgRate = Math.round(avgRate);
    //     }
    //     return avgRate
    // }
    //
    // static copyUuid() {
    //     EventBus.$emit('show-snackbar', {text: 'Uuid copié', timeout: 2000})
    // }
    //
    static isNegativeWithComment() {
        return (_.includes([1,2], AppStore.rating.rate) && !_.isEmpty(AppStore.rating.comment))
    }

    static isNegativeWithoutComment() {
        return (_.includes([1,2], AppStore.rating.rate) && _.isEmpty(AppStore.rating.comment))
    }

    static isPositiveWithComment() {
        return _.includes([3,4,5], AppStore.rating.rate) && !_.isEmpty(AppStore.rating.comment)
    }

    static isPositiveWithoutComment() {
        return _.includes([3,4,5], AppStore.rating.rate) && _.isEmpty(AppStore.rating.comment)
    }
    static isLandscape() {
        return window.innerHeight < window.innerWidth
    }
    static isPortrait() {
        return window.innerHeight > window.innerWidth
    }
    static getColor(hexa) {
        if(AppStore.is_old_version) {
            hexa = hexa.substring(0, hexa.length - 2);
        }
        return hexa
    }

    static getRateColor(isFilled) {
        let color = 'orange'
        if(isFilled){
            if(!AppStore.data.configuration.override_rate_stars_color && AppStore.data.configuration.default_rate_stars_color.hexa){
                color = AppStore.data.configuration.default_rate_stars_color.hexa
            } else {
                color = AppStore.data.configuration['override_rate_stars_color_star_'+AppStore.rating.rate].hexa
            }
        } else {
            color = 'grey lighten-0'
        }

        return color
    }

    static getButtonSize(button_size) {
        let style = ''
        switch (button_size) {
            case "small":
                style=""
                break;
            case "x-small":
                style=""
                break;
            case "large":
                style="padding: 0px 28.555556px;font-size: 1rem!important;"
                break;
            case "x-large":
                style="padding: 27px 32.111111px;font-size: 1.3rem!important;"
                break;
            default:
                style=""
        }

        return style
    }
}
