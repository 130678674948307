import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import './styles/main.scss';

import Storage from "./services/Storage";
import i18n from './config/i18n';
import './plugins/vee-validate';
import axios from 'axios';
import moment from "moment";
import Helpers from "./services/Helpers";
Vue.config.productionTip = false
import VueCookie from 'vue-cookie'

//Set default language
const actualLanguage = Storage.get('language')

if(actualLanguage){
  i18n.locale = actualLanguage
  Storage.set('language', actualLanguage)
} else {
  i18n.locale = process.env.VUE_APP_I18N_LOCALE
  Storage.set('language', process.env.VUE_APP_I18N_LOCALE)
}

//Lazy loading Vuei18n translations
const loadedLanguages = i18n.locale// our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  moment.locale(lang)
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }
  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}.js`).then(
      messages => {
        i18n.setLocaleMessage(lang, messages.default)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      }
  )
}
//End lazy loading translations

router.beforeEach((to, from, next) => {
  const lang = i18n.locale
  loadLanguageAsync(lang).then(() => next())
});


//Prototypes can be used as $helpers in template and this.$helpers in JS
Vue.prototype.$moment = moment;
Vue.prototype.$helpers = Helpers;
Vue.prototype.$cookie = VueCookie;

new Vue({
  router,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
