import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:slug',
    name: 'tb-redirect',
    component: () => import(/* webpackChunkName: "tb-redirect" */ '../views/tb/Redirect.vue')
  },
  {
    path: '/tb/1',
    component: () => import(/* webpackChunkName: "tb-1-layout" */ '../views/tb/1/Layout.vue'),

    children: [
      {
        path: '',
        name: 'tb-1-token',
        component: () => import(/* webpackChunkName: "tb-1-token" */ '../views/tb/1/Token.vue')
      },
      {
        path: ':slug',
        name: 'tb-1-home',
        component: () => import(/* webpackChunkName: "tb-1-home" */ '../views/tb/1/Home.vue')
      },
      {
        path: ':slug/appreciation',
        name: 'tb-1-appreciation',
        component: () => import(/* webpackChunkName: "tb-1-appreciation" */ '../views/tb/1/Appreciation.vue')
      },
      {
        path: ':slug/comment',
        name: 'tb-1-comment',
        component: () => import(/* webpackChunkName: "tb-1-comment" */ '../views/tb/1/Comment.vue')
      },
      {
        path: ':slug/questions',
        name: 'tb-1-questions',
        component: () => import(/* webpackChunkName: "tb-1-questions" */ '../views/tb/1/Questions.vue')
      },
      {
        path: ':slug/timeline',
        name: 'tb-1-timeline',
        component: () => import(/* webpackChunkName: "tb-1-timeline" */ '../views/tb/1/Timeline.vue')
      },
    ],
  },
  {
    path: '/tb/2',
    component: () => import(/* webpackChunkName: "tb-2-layout" */ '../views/tb/2/Layout.vue'),

    children: [
      {
        path: '',
        name: 'tb-2-token',
        component: () => import(/* webpackChunkName: "tb-2-token" */ '../views/tb/2/Token.vue')
      },
      {
        path: ':slug',
        name: 'tb-2-home',
        component: () => import(/* webpackChunkName: "tb-2-home" */ '../views/tb/2/Home.vue')
      },
      {
        path: ':slug/appreciation',
        name: 'tb-2-appreciation',
        component: () => import(/* webpackChunkName: "tb-2-appreciation" */ '../views/tb/2/Appreciation.vue')
      },
      {
        path: ':slug/comment',
        name: 'tb-2-comment',
        component: () => import(/* webpackChunkName: "tb-2-comment" */ '../views/tb/2/Comment.vue')
      },
      {
        path: ':slug/questions',
        name: 'tb-2-questions',
        component: () => import(/* webpackChunkName: "tb-2-questions" */ '../views/tb/2/Questions.vue')
      },
      {
        path: ':slug/timeline',
        name: 'tb-2-timeline',
        component: () => import(/* webpackChunkName: "tb-2-timeline" */ '../views/tb/2/Timeline.vue')
      }
    ],
  },
  {
    path: '/tb/3',
    component: () => import(/* webpackChunkName: "tb-3-layout" */ '../views/tb/3/Layout.vue'),

    children: [
      {
        path: '',
        name: 'tb-3-token',
        component: () => import(/* webpackChunkName: "tb-3-token" */ '../views/tb/3/Token.vue')
      },
      {
        path: ':slug',
        name: 'tb-3-home',
        component: () => import(/* webpackChunkName: "tb-3-home" */ '../views/tb/3/Home.vue')
      },
      {
        path: ':slug/appreciation',
        name: 'tb-3-appreciation',
        component: () => import(/* webpackChunkName: "tb-3-appreciation" */ '../views/tb/3/Appreciation.vue')
      },
      {
        path: ':slug/comment',
        name: 'tb-3-comment',
        component: () => import(/* webpackChunkName: "tb-3-comment" */ '../views/tb/3/Comment.vue')
      },
      {
        path: ':slug/questions',
        name: 'tb-3-questions',
        component: () => import(/* webpackChunkName: "tb-3-questions" */ '../views/tb/3/Questions.vue')
      },
      {
        path: ':slug/timeline',
        name: 'tb-3-timeline',
        component: () => import(/* webpackChunkName: "tb-3-timeline" */ '../views/tb/3/Timeline.vue')
      }
    ],
  },
  {
    path: '/mb/1',
    component: () => import(/* webpackChunkName: "mb-1-layout" */ '../views/mb/1/Layout.vue'),
    // beforeEnter(to, from, next) {
    //   // next(to, from, next)
    //   console.log(AppStore.data)
    //   // called before the route that renders this component is confirmed.
    //   // does NOT have access to `this` component instance,
    //   // because it has not been created yet when this guard is called!
    // },

    children: [
      {
        path: '/',
        name: 'mb-1-token',
        component: () => import(/* webpackChunkName: "mb-1-token" */ '../views/mb/1/Token.vue')
      },
      {
        path: '/mb/1/:slug',
        name: 'mb-1-home',
        component: () => import(/* webpackChunkName: "mb-1-home" */ '../views/mb/1/Home.vue')
      },
      {
        path: '/mb/1/:slug/appreciation',
        name: 'mb-1-appreciation',
        component: () => import(/* webpackChunkName: "mb-1-appreciation" */ '../views/mb/1/Appreciation.vue')
      },
      {
        path: '/mb/1/:slug/comment',
        name: 'mb-1-comment',
        component: () => import(/* webpackChunkName: "mb-1-comment" */ '../views/mb/1/Comment.vue')
      },
      {
        path: '/mb/1/:slug/questions',
        name: 'mb-1-questions',
        component: () => import(/* webpackChunkName: "mb-1-questions" */ '../views/mb/1/Questions.vue')
      },
      {
        path: '/mb/1/:slug/timeline',
        name: 'mb-1-timeline',
        component: () => import(/* webpackChunkName: "mb-1-timeline" */ '../views/mb/1/Timeline.vue')
      },
    ],
  },
  {
    path: '/mb/2',
    component: () => import(/* webpackChunkName: "mb-2-layout" */ '../views/mb/2/Layout.vue'),

    children: [
      {
        path: '/',
        name: 'mb-2-token',
        component: () => import(/* webpackChunkName: "mb-2-token" */ '../views/mb/2/Token.vue')
      },
      {
        path: '/mb/2/:slug',
        name: 'mb-2-home',
        component: () => import(/* webpackChunkName: "mb-2-home" */ '../views/mb/2/Home.vue')
      },
      {
        path: '/mb/2/:slug/appreciation',
        name: 'mb-2-appreciation',
        component: () => import(/* webpackChunkName: "mb-2-appreciation" */ '../views/mb/2/Appreciation.vue')
      },
      {
        path: '/mb/2/:slug/comment',
        name: 'mb-2-comment',
        component: () => import(/* webpackChunkName: "mb-2-comment" */ '../views/mb/2/Comment.vue')
      },
      {
        path: '/mb/2/:slug/questions',
        name: 'mb-2-questions',
        component: () => import(/* webpackChunkName: "mb-2-questions" */ '../views/mb/2/Questions.vue')
      },
      {
        path: '/mb/2/:slug/timeline',
        name: 'mb-2-timeline',
        component: () => import(/* webpackChunkName: "mb-2-timeline" */ '../views/mb/2/Timeline.vue')
      }
    ],
  },
  {
    path: '/mb/3',
    component: () => import(/* webpackChunkName: "mb-3-layout" */ '../views/mb/3/Layout.vue'),

    children: [
      {
        path: '/',
        name: 'mb-3-token',
        component: () => import(/* webpackChunkName: "mb-3-token" */ '../views/mb/3/Token.vue')
      },
      {
        path: '/mb/3/:slug',
        name: 'mb-3-home',
        component: () => import(/* webpackChunkName: "mb-3-home" */ '../views/mb/3/Home.vue')
      },
      {
        path: '/mb/3/:slug/appreciation',
        name: 'mb-3-appreciation',
        component: () => import(/* webpackChunkName: "mb-3-appreciation" */ '../views/mb/3/Appreciation.vue')
      },
      {
        path: '/mb/3/:slug/comment',
        name: 'mb-3-comment',
        component: () => import(/* webpackChunkName: "mb-3-comment" */ '../views/mb/3/Comment.vue')
      },
      {
        path: '/mb/3/:slug/questions',
        name: 'mb-3-questions',
        component: () => import(/* webpackChunkName: "mb-3-questions" */ '../views/mb/3/Questions.vue')
      },
      {
        path: '/mb/3/:slug/timeline',
        name: 'mb-3-timeline',
        component: () => import(/* webpackChunkName: "mb-3-timeline" */ '../views/mb/3/Timeline.vue')
      }
    ],
  },
  {
    path: '/widget/1/:slug',
    component: () => import(/* webpackChunkName: "widget-1-index" */ '../views/widget/1/Index.vue'),
  }
]

export default new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { x: 0, y: 0 }
  },
})
