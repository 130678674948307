import { extend, configure, setInteractionMode } from "vee-validate";
import { required, email, min, regex, numeric, required_if } from "vee-validate/dist/rules";
import i18n from '../config/i18n';

setInteractionMode('eager');

configure({
    defaultMessage: (field, values) => {
        // override the field name.
        // console.log(`fields.${field}`)
        // console.log(values)
        values._field_ = i18n.t(`fields.${field}`);

        return i18n.t(`validation.${values._rule_}`, values);
    }
});

// Install required rule and message.
extend("required", required);

// Install email rule and message.
extend("email", email);

// Install min rule and message.
extend("min", min);

// Install regex rule and message.
extend("regex", regex);

// Install numeric rule and message.
extend("numeric", numeric);

// Install required_if rule and message.
extend("required_if", required_if);

extend('verify_password', {
    validate: value => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        return strongRegex.test(value);
    },
    message: i18n.messages[i18n.locale].validation.password
});
