import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';
import i18n from "../config/i18n";
import minifyTheme from 'minify-css-string'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
            minifyTheme,
        },
        themes: {
            light: {
                primary: '#489FBB',
                secondary: '#CDDC39',
                accent: '#489FBB',
                error: '#DD1236',
                warning: '#EF6C00',
                info: '#03a9f4',
                success: '#8bc34a',
                orange : {
                    base: '#FC7753'
                },
                // Added variables
                background: '#f6f6f6',
                grey: {
                    base: '#CED3D4',
                    lighten0: '#828282',
                    lighten1: '#F2F2F2',
                    lighten2: '#CFD0D0',
                },

                textGrey: '#828080',
                lightGrey: '#4a666f',
                darkGrey: '#3B4A4F',
                dark: '#2B2D2E',
                lime: '#CDDC39',
                blue: '#327186',
                white: '#FFFFFF',

                categorization0: '#4A666F',
                categorization1: '#489FBB',
                categorization2: '#CDDC39',
                categorization3: '#279176',
                categorization4: '#44C585',
                categorization5: '#3b4a4f',
                categorization6: '#498395',
                categorization7: '#8BC421',
                categorization8: '#37A94C',

                categorization00: '#DD1236',
                categorization10: '#EF6C00',
                categorization20: '#FAB300',
                categorization30: '#F40B1F',
                categorization40: '#F83A6D',
                categorization50: '#FF580A',
                categorization60: '#ffa10a',
                categorization70: '#DD1236',
                categorization80: '#EF6C00',
            },
            dark: {
                primary: '#489FBB',
                secondary: '#CDDC39',
                accent: '#489FBB',
                error: '#DD1236',
                warning: '#EF6C00',
                info: '#03a9f4',
                success: '#8bc34a',

                // Added variables
                background: colors.grey.darken3,
                white: colors.grey.darken4,

                grey: {
                    base: '#CED3D4',
                    lighten0: '#959595',
                    lighten1: '#F2F2F2',
                    lighten2: '#CFD0D0',
                },
                orange : {
                    base: '#FC7753'
                },
                textGrey: '#555f62',
                lightGrey: '#8d8d8d',
                darkGrey: '#3b4a4f',
                dark: '#2B2D2E',
                lime: '#CDDC39',
                blue: '#327186',

                categorization0: '#489FBB',
                categorization1: '#CDDC39',
                categorization2: '#498395',
                categorization3: '#8BC421',
                categorization4: '#738D96',
                categorization5: '#44C585',
                categorization6: '#4A666F',
                categorization7: '#37A94C',
                categorization8: '#279176',

                categorization00: '#DD1236',
                categorization10: '#EF6C00',
                categorization20: '#FAB300',
                categorization30: '#F40B1F',
                categorization40: '#F83A6D',
                categorization50: '#FF580A',
                categorization60: '#FFA10A',
                categorization70: '#DD1236',
                categorization80: '#EF6C00',
            },
        },
    },
    lang: {
        t: (key, ...params) => i18n.t(key, params),
    },
    icons: {
        iconfont: 'mdi',
    },
});
