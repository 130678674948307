<template>
    <div>
        <router-view/>
    </div>
</template>

<script>
import ServiceWorkerUpdate from './mixins/ServiceWorkerUpdate'

export default {
    name: 'App',

    components: {},

    mixins: [ServiceWorkerUpdate],

    data: () => ({}),

    created() {

    },

    mounted() {

    },

    computed: {},

    watch: {},

    methods: {}
};
</script>
<style>
html, body, input:not([type=text]):not([type=email]):not([type=tel]):not([type=number]) {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

#nps-question .v-btn__content {
    font-size: 0.875rem !important;
    font-weight: 500 !important;
}

.v-label.theme--light {
    color: rgba(0, 0, 0, 1) !important;
}

.v-text-field--outlined .v-label, .v-input--selection-controls__input + .v-label, .v-select__selection--comma, .v-input, .v-list-item__title, #comment-textarea, #comment-help, .slider-chips {
    font-size: 0.875rem !important;
    font-weight: 400;
}

.v-textarea textarea {
    line-height: 1rem !important;
}

.v-input input {
    max-height: 56px;
    height: 56px;
    font-size: 0.875rem;
}

.v-text-field--outlined {
    border-radius: 16px !important;
}

.v-menu__content.theme--dark {
    box-shadow: 1px 1px 0 1px grey;
}

.block-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

#offline-icon {
    color: red;
}

@media screen and (min-width: 1200px) {
    #nps-question .v-btn__content {
        font-size: 1rem !important;
        font-weight: 400 !important;
        line-height: 1.2rem !important;
    }

    .v-label.theme--light {
        color: rgba(0, 0, 0, 1) !important;
    }

    .v-text-field--outlined .v-label, .v-input--selection-controls__input + .v-label, .v-select__selection--comma, .v-input, .v-list-item__title, #comment-textarea, #comment-help, .slider-chips {
        font-size: 1rem !important;
        font-weight: 400;
        line-height: 1.2rem !important;
    }

    .v-input input {
        max-height: 56px;
        height: 56px;
        font-size: 1rem;
        line-height: 1.2rem !important;
    }

    .rate-icon {
        letter-spacing: -9px !important;
    }

    .answer-sheet, .rating-sheet{
        width: 100%;
    }

    #carrousel .text-body-2{
        font-weight: 500!important;
    }
}

@media screen and (min-width: 600px) and (max-width: 1190px) {
	#nps-question .v-btn__content {
		font-size: 1.365rem !important;
		font-weight: 400 !important;
		line-height: 1.5rem !important;
	}

	.v-label.theme--light {
		color: rgba(0, 0, 0, 1) !important;
	}

	.v-text-field--outlined .v-label, .v-input--selection-controls__input + .v-label, .v-select__selection--comma, .v-input, .v-list-item__title, #comment-textarea, #comment-help, .slider-chips {
		font-size: 1.365rem !important;
		font-weight: 400;
		line-height: 1.5rem !important;
	}

	.v-input input {
		max-height: 56px;
		height: 56px;
		font-size: 1.365rem;
		line-height: 1.5rem !important;
	}

	.rate-icon {
		letter-spacing: -9px !important;
	}

	.answer-sheet, .rating-sheet{
		width: 100%;
	}

	#carrousel .text-body-2{
		font-weight: 500!important;
	}
}


</style>
